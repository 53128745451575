import React, { FunctionComponent, useState } from "react";

import { Typography } from "antd";
import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import { ContentCard } from "../components/ContentCard";
import { colors } from "../theme/variables";
import styled from "@emotion/styled";

const SafeEmail: FunctionComponent = () => {
  const [email, setEmail] = useState("");

  if (typeof window !== "undefined" && !email) {
    setEmail("mail@architekt-horst-ehlers.de");
  }

  return (
    <a
      href={`mailto:${email}`}
      style={{ color: `${colors.blue.primary}`, fontWeight: "bold" }}
    >
      {email}
    </a>
  );
};

const CenteredText = styled(Typography.Paragraph)`
  &.ant-typography {
    text-align: center;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${colors.blue.primary};
  }
`;

const Contact: FunctionComponent = () => {
  return (
    <>
      <SEO title={"Kontakt"} />
      <DefaultLayout>
        <ContentCard title={<StyledTitle level={4}>Adresse</StyledTitle>}>
          <CenteredText>Horst Ehlers Architekt (baugewerblich)</CenteredText>
          <CenteredText>Rudolf-Hilferding-Straße 74</CenteredText>
          <CenteredText>60439 Frankfurt am Main</CenteredText>
        </ContentCard>
        <ContentCard title={<StyledTitle level={4}>Kontakt</StyledTitle>}>
          <CenteredText>Tel. +49 (0)69 65 00 86 91</CenteredText>
          <CenteredText>Fax. +49 (0)69 65 00 86 92</CenteredText>
          <CenteredText>www.architekt-horst-ehlers.de</CenteredText>
          <CenteredText>
            <SafeEmail />
          </CenteredText>
        </ContentCard>
      </DefaultLayout>
    </>
  );
};

export default Contact;
